body.frontend{
    .modal-backdrop{
        background: $gradient_orange_blue;
        opacity: 1 !important;
    }
    .modal {
        .modal-dialog {
            max-width: 800px;
            .modal-content {
                padding: 1rem;
                background: $white-25;
                @extend .border-radius;
                border: 1px solid $white-50;
                .close {
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    background-color: white;
                    width: 30px;
                    height: 30px;
                    opacity: 1;
                    text-shadow: none;
                    border-radius: 100%;
                }
                table {
                    border: 1px dotted $white-25;
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed;
                    text-align: center;
                    tr {
                        border: 1px dotted $white-25;
                        th {
                            border: 1px dotted $white-25;
                            text-align: center;
                            padding: 0.5rem;
                            h3 {
                                margin: 1rem 0;
                                color: $white;
                            }
                        }
                        td {
                            border: 1px dotted $white-25;
                            padding: 0.5rem;
                            color: $white;
                            font-size: 0.8rem;
                            &:first-child {
                                font-weight: 900;
                                color: $white;
                            }
                            &.active {
                                background-color: $white-15;
                                color: $lilac;
                                h4{
                                    color: $lilac;
                                }
                            }
                            h4 {
                                margin: 1rem 0;
                            }
                        }
                    }
                }
                a.btn {
                    display: block;
                    padding: 7px 40px;
                    color: $white;
                    background: $gradient_orange;
                    border: none;
                    border-radius: 40px;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0 auto;
                    margin-top: 2rem;
                    @extend .font-nav;
                    @extend .transition-color;
                    max-width: 18rem;
                    &:hover{
                        color: $white;
                        text-decoration: none;
                        background: $gradient_orange_hover;
                    }
                }
            }
        }
    }
}