.cookie {
    background-color: $white;
    padding: 80px 0;
    h1 {
        margin: 60px 0;
        font-weight: 800;
        font-size: 3rem;
        background: $gradient_blue_orange;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

}
