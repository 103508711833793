/**
 * RECRE8
 * Author: Peter Sofocleous
 * Version: 1.0
 */


@import "dm-components/_variables.scss";
@import "dm-components/_fonts.scss";
@import "dm-components/base.scss";

/* FRONTEND */
@import "dm-components/frontend/header.scss";
@import "dm-components/frontend/footer.scss";
@import "dm-components/frontend/landing.scss";
@import "dm-components/frontend/cookie.scss";
@import "dm-components/frontend/login.scss";
@import "dm-components/frontend/notifications.scss";
@import "dm-components/frontend/contact-form.scss";
