body.frontend{
    &.landing{
        padding-top: 80px;
    }
    header{
        .container{
            height: 100%;
            .row{
                height: 100%;
                .brand{
                    a{
                        width: auto;
                        display: inline;
                        background: none;
                        padding: 0;
                        img{
                            width: 140px;
                            height: auto;
                        }
                    }
                }
                a{
                    width: 160px;
                    @extend .button-gradient;
                }
            }
        }
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: $white-15;
        backdrop-filter: blur(2px);
        z-index: 100;
    }
}
