#page-wrapper{
    &.no-session{
        margin: 0;
        background-image: url(../images/login-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        .login{
            color:$white;
            height: 100vh;
            >.row{
                height: 100vh;
                .card{
                    padding: 3rem;
                    @extend .border-radius;
                    .card-header{
                        background-color: $white;
                        border:none;
                        h2{
                            margin: 0;
                            color: $dark_grey;
                        }
                    }
                    .card-body{
                        label{
                            color: $dark_grey;
                            @extend .font-nav;
                        }
                        input{
                            background-color: $light_grey;
                            @extend .font-nav;
                           &[type=checkbox]{
                               position: relative;
                               top: 2px;
                           }
                        }
                        button{
                            @extend .button-gradient;
                        }
                        .form-check{
                            a, label{
                                @extend .font-nav-small;
                            }
                        }
                    }
                }
            }
        }
        .logo{
            position: fixed;
            bottom: 2rem;
            left: 2rem;
            width: 10vw;

            height: auto;
            background-color: $white;
            @extend .border-radius;
            @extend .dropshadow-big;
            .client{
                padding: 0.25rem 0.5rem;
                overflow: hidden;
            }
            .brand{
                padding: 0.25rem 0.5rem;
                background: $gradient_orange;
                border-radius: 0 0 10px 10px;
            }
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

.wrong_link {
    text-align: center;
    span {
        color: red;
        font-size: 80%;
    }
}
