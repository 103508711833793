@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

.font-nav-small{
    font: normal normal 600 10px/14px Open Sans;
}

.font-medium{
    font: normal normal 600 11px/15px Open Sans;
}


.font-meta{
    font: normal normal 600 12px/17px Open Sans;
}

.font-nav{
    font: normal normal 600 14px/19px Open Sans;
}

.font-panel-title{
    font: normal normal bold 20px/27px Open Sans;
}

.font-panel-small-title{
    font: normal normal bold 16px/22px Open Sans;
}

.font-large{
    font: normal normal 600 24px/32px Open Sans;
}

.fa{
    font-family: "Font Awesome 5 Free";
}

h1, h2, h3, h4, h5, h6{
    font-weight: bold;
    color: $white;
}

h2{
    font: normal normal bold 40px/55px Open Sans;
    margin-bottom: 3rem;
    color: $red_orange;
}
h3{
    font: normal normal 600 22px/30px Open Sans;
    margin-bottom: 3rem;
    color: $red_orange;
}