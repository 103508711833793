body.frontend{

    footer{
        .container{
            height: 100%;
            .row{
                height: 100%;
                .brand{
                    img{
                        width: 140px;
                        height: auto;
                    }
                }
                a{
                    color: $white;
                    text-transform: uppercase;
                    @extend .font-nav-small;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        min-height: 120px;
        padding: 2rem 0;
        background-color: $dark_grey;
        color: $white;

        .copyright{
            padding-top: 2rem;
            font-size: 0.6rem;
        }

        .modal {
            .modal-dialog {
                .modal-content {
                    h2 {
                        color: $white;
                    }
                    form {
                        label {
                            &.agree:hover {
                                cursor: pointer;
                            }
                        }
                        .form-group {
                            button {
                                display: block;
                                padding: 7px 40px;
                                color: $white;
                                background: $gradient_orange;
                                border: none;
                                border-radius: 40px;
                                text-align: center;
                                text-transform: uppercase;
                                margin: 0 auto;
                                @extend .font-nav;
                                @extend .transition-color;
                                &:hover{
                                    color: $white;
                                    text-decoration: none;
                                    background: $gradient_orange_hover;
                                }
                            }
                            .success, .fail {
                                display: none;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
