.alert-success {
    color: $green;
    background-color: inherit;
    border: 1px dotted #c3e6cb;
    text-align: center;
}

.alert-danger {
    border: 1px dotted #f5c6cb;
    background-color: inherit;
    color: $red;
    text-align: center;
}
