// Basic Colours
$old_black: #000000;
$old_pink: #d100c7;
$old_grey: #aaaaaa;
$old_mid_grey: #dddddd;
$old_dark_grey: #666666;
$old_light_grey: #f4f4f4;
$old_red: red;
$old_green: #088108;
$old_blue: #2E629E;

// RGBA Colours
$old_black-80: rgba(0,0,0,0.8);
$old_black-90: rgba(0,0,0,0.9);
$white-10: rgba(225,225,225,0.1);
$white-30: rgba(225,225,225,0.3);

// Hover Colours
$old_pink-hover: #ff00f2;
$old_green-hover: #066306;
$old_grey-hover: #838383;

// BRAND COLOURS
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);

$off_black: rgba(44, 43, 54, 1);
$nearly_black: rgba(23, 23, 23, 1);

$darkest_grey: rgba(34, 34, 34, 1);
$dark_grey_black: rgba(52, 51, 65, 1);
$dark_grey: rgba(38, 38, 38, 1);
$dark_grey_blue: rgba(51, 52, 77, 1);
$mid_grey: rgba(59, 58, 74, 1);
$light_grey: rgba(204, 204, 204, 1);
$dark_grayish_blue: rgba(128, 127, 152, 1);

$mid_blue: rgba(41, 43, 64, 1);
$dark_blue: rgba(33, 34, 52, 1);
$lilac: rgba(73, 75, 103, 1);

$orange: rgba(255, 122, 93, 1);
$pink: rgba(246, 86, 126, 1);
$purple: rgba(164, 90, 175, 1);
$blue: rgba(79, 144, 209, 1);
$green: rgba(67, 173, 28, 1);
$red: rgba(209, 75, 75, 1);
$red_orange: rgba(228, 96, 126, 1);

$pink-10: rgba(246, 86, 126, 0.1);
$black-16: rgba(0, 0, 0, 0.16);
$black-20: rgba(0, 0, 0, 0.2);
$blue-20: rgba(79, 144, 209, 0.2);
$blue-50: rgba(79, 144, 209, 0.5);
$white-15: rgba(255, 255, 255, 0.15);
$white-25: rgba(255, 255, 255, 0.25);
$white-50: rgba(255, 255, 255, 0.5);
$white-80: rgba(255, 255, 255, 0.8);

// GRADIENTS
$gradient_white_base: transparent linear-gradient(270deg, $white 0%, $white 100%) 0% 0% no-repeat padding-box;
$gradient_orange: transparent linear-gradient(270deg, $pink 0%, $orange 100%) 0% 0% no-repeat padding-box;
$gradient_orange_hover: transparent linear-gradient(270deg, $pink 100%, $orange 100%) 0% 0% no-repeat padding-box;
$gradient_orange_solid: transparent linear-gradient(270deg, $pink 0%, $pink 100%) 0% 0% no-repeat padding-box;
$gradient_purple_pink: transparent linear-gradient(90deg, $purple 0%, $pink 100%) 0% 0% no-repeat padding-box;
$gradient_blue_orange: transparent linear-gradient(90deg, rgba(75,144,209,1) 0%, rgba(92,133,202,1) 7%, rgba(164,90,175,1) 53%, rgba(255,122,93,1) 82%) no-repeat padding-box;
$gradient_orange_blue: transparent linear-gradient(135deg, rgba(255,122,93,1) 18%, rgba(164,90,175,1) 75%, rgba(92,133,202,1) 93%, rgba(75,144,209,1) 100%) no-repeat padding-box;
