.landing{
    background-color: $white;
    section{
        min-height: auto;
        padding: 8rem 0;
        h2{
            color: $white;
        }
        &.intro{
            padding: 8rem 0 2rem;
            h1{
                font-weight: 800;
                font-size: 3rem;
                background: $gradient_blue_orange;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

        }
        &.intro-video{

        }
        &.highlight-features {
            .highlight-block {

                img {
                    width: 100%;
                }
                h2 {
                    color: $black;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin: 1.5rem 0 0.5rem 0;
                    font-size: 1.7rem;
                    padding: 0 5%;
                }
                h3 {
                    margin-bottom: 1.5rem;
                    font-weight: bold;
                    font-size: 2.3rem;
                    line-height: 1.2;
                    padding: 0 5%;

                }
                p {
                    font-size: 1.2rem;
                    padding: 0 5%;
                }
                &:first-child {
                    h3 {
                        color: $orange;
                    }
                }
                &:nth-child(2){
                    h3 {
                        color: $purple;
                    }
                }
                &:nth-child(3){
                    h3 {
                        color: $pink;
                    }
                }

                &:last-child{
                    h3 {
                        color: $blue;
                    }
                }
            }

        }
        &.features{
            min-height: 100vh;
            background-color: $lilac;
            color: $white;
            .features-sidebar {
                margin-top: 2rem;
                ul {
                    li {
                        list-style-type: none;
                        margin-bottom: 1rem;
                        a {
                            color: $purple;
                            font-size: 20px;
                            i {
                                margin-right: 1rem;
                            }
                            &.active {
                                color: $orange
                            }
                            &:hover {
                                text-decoration: none;
                                color: $orange
                            }
                        }
                    }
                }
            }
            .features-slider {
                margin-top: 2rem;
                img, video {
                    display: block;
                    width: 100%;
                    border-radius: 12px;
                }
            }
        }
        &.packages{
            min-height: 100vh;
            background: $gradient_orange_blue;
            color: $white;
            .panel{
                padding: 1rem;
                background: $white-25;
                @extend .border-radius;
                @extend .transition;
                border: 1px solid $white-50;
                h3{
                    margin-bottom: 1rem;
                    color: $white;
                }
            }
            .choose-package {
                &:hover {
                    margin-top: -1rem;
                    margin-bottom: -1rem;
                    cursor: pointer;
                    background: $white-50;
                }
            }
        }

    }
    
}
