html,
body {
    height: 100%;
    background-color: $mid_grey;
    font-family: 'Open Sans', sans-serif;

    #wrapper{
        background-color: $dark_grey_black;
        #page-wrapper{
            min-height: 100vh;
            background-color: $dark_blue;
            transition: none;
            padding: 0;
        }
    }

    &.frontend.landing{
        margin: 0;
        background-color: $white;
        #wrapper{
            background-color: $white;
            #page-wrapper{
                background-color: $white;
            }
        }
    }

    &.no-nav{
        #wrapper{
            #page-wrapper{
                margin: 0;
            }
        }
    }
}


.clear {
    clear: both;
}

.tooltip {
    border: none;
    background-color: $mid_grey;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    display: block;
}



.modal-backdrop
{
    opacity:0.5 !important;
}

.no-gutters {
    padding-right: 0;
    padding-left: 0;
    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.transition {
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.transition-color {
    -webkit-transition: color 0.25s ease-out;
    -moz-transition: color 0.25s ease-out;
    -ms-transition: color 0.25s ease-out;
    -o-transition: color 0.25s ease-out;
    transition: color 0.25s ease-out;
}

/* BOOTSTRAP */
.btn{
    padding-bottom: 3px;
    &.btn-view {
        padding: 0.5rem 0.75rem;
    }
}
.btn-primary, .btn-primary:visited  {
    color: $white;
    background-color: $old_pink;
}

.btn-primary:hover {
    color: $white;
    background-color: $old_pink-hover;
}

.btn-save{
    color: $white;
    background-color: $old_green;
    &:hover{
        color: $white;
        background-color: $old_green-hover;
    }
}

.btn-default{
    color: $white;
    background-color: $old_grey;
    &:hover{
        color: $white;
        background-color: $old_grey-hover;
    }
}

img{
    &.thumb{
        width: auto;
        height: auto;
    }
    &.thumb-100{
        max-width: 100px;
    }
    &.thumb-640{
        max-width: 640px;
    }
}

.border-radius{
    border-radius: 10px;
}
.border-radius-full{
    border-radius: 100%;
}
.border-top-left-radius {
    border-top-left-radius: 10px;
}
.border-bottom-left-radius {
    border-bottom-left-radius: 10px;
}
.border-top-right-radius {
    border-top-right-radius: 10px;
}
.border-bottom-right-radius {
    border-bottom-right-radius: 10px;
}
.border-radius-feature{
    border-radius: 40px 8px 8px 8px;
}

.dropshadow{
    -webkit-box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
}

.dropshadow-big{
    -webkit-box-shadow: 0 0 10px 4px rgba(0,0,0,0.5);
    box-shadow: 0 0 20px 4px rgba(0,0,0,0.5);
}

.btn{
    padding: 0.5rem 1rem;
    text-align: left;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    text-transform: uppercase;
    @extend .transition;
    @extend .font-nav;
    &.button-back, &.button-white{
        color: $white;
        border-color: $white;
        &:hover{
            color: $red_orange;
            border-color: $red_orange;
        }
    }

    &.button-red{
        color: $red_orange;
        border-color: $red_orange;
        &:hover{
            color: $white;
            background-color: $red_orange;
        }
    }

    &.button-red-full{
        color: $white;
        border-color: $red_orange;
        background-color: $red_orange;
        &:hover{
            color: $red_orange;
            border-color: $white;
            background-color: $white;
        }
    }

    &.button-white{
        color: $white;
        border-color: $white;
        &:hover, &.open{
            color: $red_orange;
            border-color: $red_orange;
        }
    }

    &.button-outlined{
        background-color: $white;
        color: $dark_grey;
        border-color: $dark_grey;
        &:hover, &.open{
            background-color: $white;
            color: $red_orange;
            border-color: $red_orange;
        }
    }

    &.button-gradient{
        position: relative;
        top: -1px;
        background: $gradient_orange;
        color: $white;
        border:1px solid transparent;
        &:hover{
            background: $gradient_orange_solid;
        }
    }
    &.button-submit{
        background: $gradient_orange;
        color: $white;
        border:1px solid transparent;
        &:hover{
            background: $gradient_orange_solid;
        }
    }
    &.button-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        margin: 0 5px;

        border-radius: 4px;
    }
    &.button-text{
        border: none;
        padding: 0;
        text-transform: capitalize;
    }
    &.button-disabled{
        opacity: 0.2;
        pointer-events: none;
    }
    &.button-100{
        width: 100%;
    }
    &.button-dotted{
        border-style: dotted;
    }
}

.button-icon-round{
    display: block;
    width: 97px;
    color: $white;
    @extend .transition;
    span{
        display: inline-block;
        width: 100%;
        margin: 1em 0 2em;
        @extend .font-nav-small;
    }
    i{
        display: inline-block;
        width: 56px;
        height: 56px;
        padding: 17px;
        border-radius: 100%;
        background: $gradient_white_base;
        color: $dark_blue;
        font-size: 1.5em;
    }
    &:hover, &.active{
        i{
            color: $white;
            background: $gradient_orange;
        }
    }
    &.btn-disabled{
        opacity: 0.2;
        &:hover{
            i{
                background: $gradient_white_base;
                color: $dark_blue;
            }
        }
    }
}
/*
.button-gradient{
    display: inline-block;
    width: 100%;
    padding: 7px 0;
    color: $white;
    background: $gradient_orange;
    border: none;
    border-radius: 40px;
    text-align: center;
    text-transform: uppercase;

    @extend .font-nav;
    @extend .transition-color;
    &:hover{
        color: $white;
        text-decoration: none;
        background: $gradient_orange_hover;
    }
}*/

.button-gradient-grey{
    @extend .button-gradient;
    background: $dark_grey_blue;
    &:hover{
        background: $lilac;
    }
}

.button-gradient-clear{
    border: 1px solid $white;
    background:transparent;
    @extend .transition;

    &:hover{
        background: $blue;
    }
}

.button-gradient-blue{
    border: none;
    background:$blue;
    @extend .transition;

    &:hover{
        background: $blue-20;
    }
}

.context-menu-cont{
    position: relative;
    .context-menu-items{
        flex-direction: column;
        position: absolute;
        top: calc(100% + 0.5rem);
        padding: 0.5rem 1rem;
        min-width: 300%;
        right: 0;
        display: none;
        opacity: 0;
        background-color: $dark_blue;
        border: 1px solid $white-25;
        border-radius: 4px;
        color: $white;
        text-align: left;
        @extend .transition;
        a{
            display: block;
            margin: 0.25rem 0;
            color: $white;
            white-space: nowrap;
            text-transform: capitalize;
            font-weight: normal;
            @extend .font-nav;
            &:hover{
                color: $red_orange;
                text-decoration: none;
            }
            i{
                width: 15px;
                margin-right: 0.5rem;
            }
        }
        hr{
            margin: 0.25rem 0;
            height: 1px;
            width: 100%;
        }
    }
    &.open{
        z-index: 10000;
        .context-menu{
            i{
                color: $red_orange;
            }
        }
        .context-menu-items{
            display: flex;
            opacity: 1;
        }
    }
}

.breadcrumbs {
    padding: 0.5rem 0 0.5rem 3rem;
    background-color: $off_black;
    &.pl{
        padding-left: 0;
    }
    h6 {
        margin: 0;
        font-weight: normal;
        color: $dark_grayish_blue;
        font-size: 0.9rem;
        a {
            color: $red_orange;
        }
        span {
            color: $dark_grayish_blue;
        }
    }
}

.page{
    padding: 3rem;
    color: $white;
    h1{
        margin: 2rem 0 3rem 0;
        .button-icon{
            position: relative;
            top: -5px;
        }
    }
    h5{
        margin: 0 0 2rem 0;
        @extend .font-nav;
    }
    .button-gradient{
        width: auto;
        padding: 0.5rem 2rem;
    }
    .panel{
        display: block;
        padding: 1rem;
        margin-bottom: 2rem;
        background-color: $mid_grey;
        color: $white;
        @extend .border-radius;
        &.border-radius-feature{
            @extend .border-radius-feature;
        }
        &:hover{
            background-color: $dark_grey_blue;
            text-decoration: none;
        }
        h2{
            display: inline-block;
            line-height: 3rem;
            margin:0 0 2rem 0;
            .icon{
                position: relative;
                top:-0.25rem;
                display: inline-block;
                width: 2.5rem;
                height: 2.5rem;
                font-size: 1rem;
                margin: 0 1rem 0 0;
                background-color: $white;
                border-radius: 100%;
                color: $orange;
                line-height: 2.5rem;
                text-align: center;
            }
            .under-title {
                display: block;
                position: absolute;
                top: 2.5rem;
                left: 5.5rem;
                font-size: 0.8rem;
                text-transform: uppercase;
                color: $dark_grayish_blue;
                font-weight: 600;
            }
        }
        h3{
            margin: 0;
            color: $white;
        }
        .panel_desc {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 3.6em;
            line-height: 1.8em;
        }
    }
    hr{
        border-color: $white-25;
    }
}

.alert{
    &.alert-fixed{
        position: fixed;
        right: 1rem;
        top: 1rem;
        @extend .font-nav-small;
        .close{
            padding: 0.45rem .45rem;
        }
    }

}

.alert_group {
    position: fixed;
    right: 1rem;
    top: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 100;
    .alert{
        @extend .font-nav-small;
        .close{
            padding: 0.45rem .45rem;
        }
    }
}

.swal2-container{
    .swal2-popup{
        width: 20em;
        .swal2-content{
            padding: 1rem 0 0 0;
        }
        h2{
            color: $black;
            @extend .font-large;
        }
        button{
            text-align: center;
            @extend .btn;
            @extend .button-100;
            &.swal2-confirm{
                @extend .button-gradient;
            }
            &.swal2-deny{
                @extend .button-outlined;
                &:hover{
                    background-image: none;
                }
            }
            &.swal2-cancel{
                margin-top: 1rem;
                @extend .button-outlined;
                &:hover{
                    background-image: none;
                }
            }
        }
    }
}
